import React from 'react';
import { useIntl } from 'react-intl';

export const AccountCenter: React.FC = () => {
  const intl = useIntl();
  return (
    <sgwt-account-center
      available-languages='fr,en'
      authentication='sg-connect-v2'
      mode='sg-markets'
      language={intl.locale}
    />
  );
};
