import * as React from "react";

export interface Props {
  condition: boolean;
  elseComponent?: React.ReactNode;
  children: React.ReactNode;
}

export const If: React.FC<Props> = ({ condition, children, elseComponent }: Props) => (
  <>{condition ? children : elseComponent || null}</>
);
