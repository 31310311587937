import * as React from "react";
interface LabelProps {
  displayLabel: string;
  htmlFor?: string;
  className?: string;
  required?: boolean;
}

export const Label: React.FunctionComponent<LabelProps> = ({ displayLabel, htmlFor, className, required }: LabelProps) => {
  const label = !required? displayLabel : `${displayLabel}*`
  return (
  <label htmlFor={htmlFor} className={className}>
    {label}
  </label>
);
}
