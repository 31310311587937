import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "../../context/IntlContext";
import { GlobalContext } from "../../context/GlobalContext";
import { Header, HelpCenter, Footer, PageNotFound } from "../common";
import { Home } from "../routes/Home/Home";
import "../../styles/main.scss";
import { ErrorCatch } from "../common/ErrorCatch";
import { QueryClient, QueryClientProvider } from "react-query";
import { toast } from "react-toastify";
import { Authentication } from "../common/Authentication";

const App: React.FC = () => {
  /*   const matomo = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_SERVER!,
    siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID!, 10),
    trackerUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.js`,
  }); */
  toast.configure({
    hideProgressBar: true,
    position: "top-center",
    style: {whiteSpace: "pre-line", width: "35em"}
  });
  const context = {};
  const queryClient = new QueryClient();

  return (
    <ErrorCatch>
      <IntlProvider>
        <GlobalContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <Router basename="/index.html">
                <Header />
                <main id="main" role="main" className="container-fluid d-flex flex-column">
                  <div className="flex-grow-1">
                    <Authentication>
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route component={PageNotFound} />
                      </Switch>
                    </Authentication>
                  </div>
                  <HelpCenter />
                </main>
                <Footer />
              </Router>
            </React.StrictMode>
          </QueryClientProvider>
        </GlobalContext.Provider>
      </IntlProvider>
    </ErrorCatch>
  );
};

export default App;
