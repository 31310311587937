import * as React from "react";
import { convertFileSizeToMb } from "./sizeFormats";
import { InvalidFeedback } from "../ValidationMessage/InvalidFeedback";
import { Icon } from "../Icon/Icon";
export interface DropZoneProps {
  onFileSelectSuccess: (file: File) => void;
  onFileAdded: (file: File | null) => void;
  placeholder?: string;
}
export const DropZone: React.FC<DropZoneProps> = (props: DropZoneProps) => {
  const { onFileSelectSuccess, onFileAdded, placeholder = "Drag your file to this area to upload" } = props;
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [isValidFileSize, setIsValidFileSize] = React.useState(true);

  const openFileDialog = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files![0];

    if (file && convertFileSizeToMb(file.size) > 4) {
      setIsValidFileSize(false);
      onFileAdded(null);
    } else {
      setIsValidFileSize(true);
      onFileAdded(file);
      onFileSelectSuccess(file);
    }
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { files } = event.dataTransfer;

    if (files && files.length && convertFileSizeToMb(files[0].size) > 4) {
      setIsValidFileSize(false);
      onFileAdded(null);
    } else {
      setIsValidFileSize(true);
      onFileAdded(files[0]);
      onFileSelectSuccess(files[0]);
    }
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="file-uploader mt-1" onDrop={handleOnDrop} onDragOver={handleOnDragOver}>
        <div className="position-relative d-block">
          <div className="upload-squares" />

          <div className="upload-inner text-center d-flex align-items-center justify-content-center">
            <Icon iconName="attach_file" className="text-light" size="md" />
            <label className={`text-info px-1 mb-0 pointer`} onClick={openFileDialog}>
              Select /
            </label>
            <p className="text-secondary m-2 line-height-1">{placeholder}</p>

            <input className="d-none" type="file" ref={fileInputRef} onChange={e => handleFileInput(e)} />
          </div>
          <div className="upload-squares" />
        </div>
      </div>
      {!isValidFileSize && <InvalidFeedback errorMessage="File size should be less than 4MB" errorType={"danger"} />}
    </>
  );
};
