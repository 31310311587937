import { ReferentialNumber } from "../../../common/common.typings";
import { SupplierFrequency } from "./suppliers.typings";

export const computeYears = (): ReferentialNumber[] => {  
  const fullYear = new Date().getFullYear();
  return [
    { id: fullYear, label: fullYear.toString() },
    { id: (fullYear - 1), label: (fullYear - 1).toString() },
    { id: (fullYear - 2), label: (fullYear - 2).toString() },
    { id: (fullYear - 3), label: (fullYear - 3).toString() },
  ];
}

export const computeMonths = (frequency: string): ReferentialNumber[] => {
  const months = [
    { id: 1, label: "January" },
    { id: 2, label: "February" },
    { id: 3, label: "March" },
    { id: 4, label: "April" },
    { id: 5, label: "May" },
    { id: 6, label: "June" },
    { id: 7, label: "July" },
    { id: 8, label: "August" },
    { id: 9, label: "September" },
    { id: 10, label: "October" },
    { id: 11, label: "November" },
    { id: 12, label: "December" },
  ];

  switch(frequency) {
    case SupplierFrequency.Monthly: {
      return months;
    }
    case SupplierFrequency.Quarterly: {
      return [
        months[2],
        months[5],
        months[8],
        months[11]
      ];
    }
    case SupplierFrequency.HalfYearly: {
      return [
        months[5],
        months[11]
      ];
    }
    case SupplierFrequency.Yearly: {
      return [
        months[11]
      ];
    }
    default: {
      return [];
    }
  }
}

export const buildAccountingPeriod = (year: number, month: number): string => {
  const accountingPeriodMonth = month.toString().length === 1 ? `0${month}` : month;
  return `${year.toString()}${accountingPeriodMonth}`;
}