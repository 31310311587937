import React, { Fragment, useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from "../helpers";
import en from "../locales/en.json";
import fr from "../locales/fr.json";

type SupportedLocale = "en" | "fr";
export const locales: { [key in SupportedLocale]: { [key: string]: string } } = {
  en,
  fr,
};

export const IntlProvider: React.FC = ({ children }) => {
  const [locale, setLocale] = useState<SupportedLocale>("en");

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();
    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, (lang) => {
        if (lang && Object.keys(locales).indexOf(lang.toLowerCase()) >= 0) {
          setLocale(lang.toLowerCase() as SupportedLocale);
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
  }, []);

  return (
    <ReactIntlProvider locale={locale} messages={locales[locale]} textComponent={Fragment}>
      {children}
    </ReactIntlProvider>
  );
};
