import React from "react";

export const Footer: React.FC = () => {
  return (
    <footer>
      <sgwt-mini-footer
        type="compact"
        design="light"
        container="container"
        mode="sg-markets"
        contact-us-by-help-center="sgwt-help-center"
      />
    </footer>
  );
};
