import { CheckLastSuccessUploadDto, FileUploadDto, FileUploadHistoryDtos, FileUploadResponseDto, UploadStatusesDto } from "../components/DataCollect/common/upload.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { IUploadApi } from "./upload.api.typing";

export const UploadApi: IUploadApi = {
  postUpload: async (dto: FileUploadDto): Promise<FileUploadResponseDto> => {
    const formData = new FormData();

    formData.append("supplierMnemo", dto.supplier.supplierMnemo);
    const month = dto.accountingPeriodMonth < 10 ? `0${dto.accountingPeriodMonth}` : dto.accountingPeriodMonth.toString();
    formData.append("accountingPeriod", `${dto.accountingPeriodYear}${month}`);
    formData.append("uploadFrequency", dto.supplier.uploadFrequency);
    const arrayBuffer = await dto.file!.arrayBuffer();
    formData.append("file", new Blob([new Uint8Array(arrayBuffer)], { type: dto.file!.type }), dto.file!.name);

    return createRepository().post<FormData, FileUploadResponseDto>(
      `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload`,
      getAuthenticationHeader(),
      formData
    );
  },
  getUploadStatuses: async (): Promise<UploadStatusesDto> => createRepository().get<UploadStatusesDto>(
    `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload/statuses`,
    getAuthenticationHeader()
  ),
  CheckLastSuccessUpload: async (supplierMnemo: string, accountingPeriod: string): Promise<CheckLastSuccessUploadDto> => createRepository().get<CheckLastSuccessUploadDto>(
    `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload/checklastsuccessupload?supplierMnemo=${supplierMnemo}&accountingPeriod=${accountingPeriod}`,
    getAuthenticationHeader()
  ),
  DownloadUploadFile: async (uploadId: number): Promise<Response> => createRepository().getStream(
    `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload/${uploadId}/download`,
    getAuthenticationHeader()
  ),
  getUploads: async (accountingPeriodFrom: string, accountingPeriodTo: string, supplierIds: number[], statuses: string[]): Promise<FileUploadHistoryDtos> => createRepository().get<FileUploadHistoryDtos>(
    `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload/search?accountingPeriodFrom=${accountingPeriodFrom}&accountingPeriodTo=${accountingPeriodTo}&${supplierIds.map(x => "supplierIds=" + x).join("&")}&${statuses.map(x => "statuses=" + x).join("&")}`,
    getAuthenticationHeader()
  ),
  downloadUnknownClientsFile: async (uploadId: number): Promise<Response> => createRepository().getStream(
    `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/upload/${uploadId}/unknownclients/download`,
    getAuthenticationHeader()
  ),
};