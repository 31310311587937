import React from "react";
import { useGetUploads } from "../../../queries/upload";
import { buildAccountingPeriod } from "../common/common.helpers";
import { SearchFilters } from "./SearchFilters/SearchFilters";
import { SearchFiltersState } from "./SearchFilters/SearchFilters.typings";
import { UploadGrid } from "./UploadGrid";

interface Props {
  refreshUploadGrid: boolean;
}
export const UploadGridContainer: React.FC<Props> = ({ refreshUploadGrid }: Props) => {
  const [searchFiltersState, setSearchFiltersState] = React.useState<SearchFiltersState>();
  const { data: uploads, refetch: refetchUploads } = useGetUploads(
    buildAccountingPeriod(searchFiltersState?.accountingPeriodYearFrom.id ?? 0, searchFiltersState?.accountingPeriodMonthFrom.id ?? 0), 
    buildAccountingPeriod(searchFiltersState?.accountingPeriodYearTo.id ?? 0, searchFiltersState?.accountingPeriodMonthTo.id ?? 0), 
    searchFiltersState?.suppliers.map(x => x.id) ?? [], 
    searchFiltersState?.statuses.map(x => x.id) ?? [], false
  );

  React.useEffect(() => {
    if (searchFiltersState) {
      refetchUploads();
    }
  }, [searchFiltersState, refetchUploads]);

  React.useEffect(() => {
    if (refreshUploadGrid) {
      refetchUploads();
    }
  }, [refreshUploadGrid, refetchUploads]);

  return (
    <div>
      <h4 className="mb-3 display-4">Data Collect History</h4>
      <SearchFilters
        onSearchFiltersClicked={(filters: SearchFiltersState) => {
          setSearchFiltersState(filters);
        }}
      />
      <hr/>
      <UploadGrid uploadHistory={uploads ?? []} />
    </div>
  );
}