import { Button } from "@sgbs-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { FileUploadModal } from "../../DataCollect/FileUpload/FileUploadModal";
import { UploadGridContainer } from "../../DataCollect/UploadGrid/UploadGridContainer";

export const Home: React.FC = () => {
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const [refreshUploadGrid, setRefreshUploadGrid] = React.useState(false);

  const openModalFileUpload = () => {
    setShowUploadModal(true);
    setRefreshUploadGrid(false);
  };

  const onCloseModalFileUpload = () => {
    setShowUploadModal(false);
  };

  const handleRefreshUploadGrid = () => {
    setRefreshUploadGrid(true);
  };

  return (
    <div className="main-content">
      <div className="row pl-2">
        <div className="col-12 col-sm-8 p-4">
          <h3 className="display-3">
            <FormattedMessage id="home.welcome" />
          </h3>
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label className="mr-2">Upload a new file :</label> <Button onClick={openModalFileUpload}>Upload file</Button>
          <FileUploadModal show={showUploadModal} onClose={onCloseModalFileUpload} onSuccessFileUpload={handleRefreshUploadGrid} />
        </div>
        <hr />
        <UploadGridContainer refreshUploadGrid={refreshUploadGrid} />
      </div>
    </div>
  );
};
