import { Button, Select } from "@sgbs-ui/core";
import React from "react";
import { ReferentialNumber } from "../../../../common/common.typings";
import { useGetUploadStatuses } from "../../../../queries/upload";
import MultiSelectPicker from "../../../common/MultiSelector/MultiSelectPicker";
import { Label } from "../../../common/ReadOnlyFields/Label";
import { computeMonths, computeYears } from "../../common/common.helpers";
import { useGetAuthorizedSuppliers } from "../../common/hooks/useGetAuthorizedSuppliers";
import { SupplierFrequency, SupplierDto } from "../../common/suppliers.typings";
import { UploadStatusDto } from "../../common/upload.typings";
import { SearchFiltersState } from "./SearchFilters.typings";

export interface SearchFiltersProps {
  onSearchFiltersClicked: (searchFilters: SearchFiltersState) => void;
}

const allMonths: ReferentialNumber[] = computeMonths(SupplierFrequency.Monthly);
const allYears: ReferentialNumber[] = computeYears();

const INIT_STATE: SearchFiltersState = {
  accountingPeriodMonthFrom: { id: 0, label: "Month"},
  accountingPeriodYearFrom: { id: 0, label: "Year"},
  accountingPeriodMonthTo: { id: 0, label: "Month"},
  accountingPeriodYearTo: { id: 0, label: "Year"},
  suppliers: [],
  statuses: [],
};

export const SearchFilters: React.FC<SearchFiltersProps> = ({ onSearchFiltersClicked }: SearchFiltersProps) => {
  const [suppliers] = useGetAuthorizedSuppliers();
  const [searchFiltersState, setSearchFiltersState] = React.useState<SearchFiltersState>(INIT_STATE);
  const [fromGreaterThanTo, setFromGreaterThanTo] = React.useState<boolean>(false);
  const { data: uploadStatusesResponse, refetch: refetchStatuses } = useGetUploadStatuses(false);

  React.useEffect(() => {
    if (uploadStatusesResponse && suppliers) {
      resetForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatusesResponse, suppliers]);

  React.useEffect(() => {
    refetchStatuses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const accountingPeriodDateFrom = new Date(searchFiltersState.accountingPeriodYearFrom.id, (searchFiltersState.accountingPeriodMonthFrom.id - 1), 1);
    const accountingPeriodDateTo = new Date(searchFiltersState.accountingPeriodYearTo.id, (searchFiltersState.accountingPeriodMonthTo.id - 1), 1);    
    setFromGreaterThanTo(accountingPeriodDateFrom > accountingPeriodDateTo); 
  }, [searchFiltersState.accountingPeriodYearFrom, searchFiltersState.accountingPeriodMonthFrom, searchFiltersState.accountingPeriodYearTo, searchFiltersState.accountingPeriodMonthTo]);
   
  const resetForm = (): void => {
    const newState: SearchFiltersState = {
      accountingPeriodMonthFrom: allMonths.find(x => x.id === 1)!,
      accountingPeriodMonthTo: allMonths.find(x => x.id === (new Date().getMonth() + 1))!,
      accountingPeriodYearFrom: allYears[0],
      accountingPeriodYearTo: allYears[0],
      suppliers: suppliers ?? [],
      statuses: uploadStatusesResponse?.values.filter(x => x.id !== "canceled") ?? []
    };
    setSearchFiltersState(newState);
    onSearchFiltersClicked(newState);
  }

  const isSearchFilterValid = (): boolean => {
    const accountingPeriodFromIsValid: boolean = (searchFiltersState.accountingPeriodMonthFrom.id !== 0 && searchFiltersState.accountingPeriodYearFrom.id !== 0)
      || (searchFiltersState.accountingPeriodMonthFrom.id === 0 && searchFiltersState.accountingPeriodYearFrom.id === 0);

    const accountingPeriodToIsValid: boolean = (searchFiltersState.accountingPeriodMonthTo.id !== 0 && searchFiltersState.accountingPeriodYearTo.id !== 0)
      || (searchFiltersState.accountingPeriodMonthTo.id === 0 && searchFiltersState.accountingPeriodYearTo.id === 0);

    return accountingPeriodFromIsValid
    && accountingPeriodToIsValid && !fromGreaterThanTo;
  }

  const handleSearchFilterClick = (): void => {
    onSearchFiltersClicked(searchFiltersState);
  }

  return (
    <div className="row">
      <div className="col-md-2 form-group">
        <Label displayLabel={"From"} htmlFor={"From"} />
        <div className="mb-2">
          <Select.SingleSelect<ReferentialNumber>
            idField="id"
            labelField="label"
            items={allMonths}
            showClearButton={false}
            selectedItem={searchFiltersState.accountingPeriodMonthFrom}
            color={searchFiltersState.accountingPeriodMonthFrom.id === 0 ? "secondary" : "info"}
            onChange={(e: any) => {
              setSearchFiltersState({ 
                ...searchFiltersState, 
                accountingPeriodMonthFrom: e as ReferentialNumber 
              });
            }}
          />
        </div>
        <div>
          <Select.SingleSelect<ReferentialNumber>
            idField="id"
            labelField="label"
            items={allYears}
            showClearButton={false}
            selectedItem={searchFiltersState.accountingPeriodYearFrom}
            color={searchFiltersState.accountingPeriodYearFrom.id === 0 ? "secondary" : "info"}
            onChange={(e: any) => {
              setSearchFiltersState({ 
                ...searchFiltersState, 
                accountingPeriodYearFrom: e as ReferentialNumber 
              });
            }}
          />
        </div>      
        <>{fromGreaterThanTo &&
            <div className="row"> 
              <div className="col-md-12">
                <Label displayLabel="End date should be greater than start date." className="text-danger" />
              </div>
            </div>
          }</>      
      </div>
      <div className="col-md-2 form-group">
        <Label displayLabel={"To"} htmlFor={"To"} />
        <div className="mb-2">
          <Select.SingleSelect<ReferentialNumber>
            idField="id"
            labelField="label"
            items={allMonths}
            showClearButton={false}
            selectedItem={searchFiltersState.accountingPeriodMonthTo}
            color={searchFiltersState.accountingPeriodMonthTo.id === 0 ? "secondary" : "info"}
            onChange={(e: any) => {
              setSearchFiltersState({ 
                ...searchFiltersState, 
                accountingPeriodMonthTo: e as ReferentialNumber 
              });
            }}
          />
        </div>
        <div>
          <Select.SingleSelect<ReferentialNumber>
            idField="id"
            labelField="label"
            items={allYears}
            showClearButton={false}
            selectedItem={searchFiltersState.accountingPeriodYearTo}
            color={searchFiltersState.accountingPeriodYearTo.id === 0 ? "secondary" : "info"}
            onChange={(e: any) => {
              setSearchFiltersState({ 
                ...searchFiltersState, 
                accountingPeriodYearTo: e as ReferentialNumber 
              });
            }}
          />
        </div>
      </div>
      <div className="col-md-3 form-group">
        <Label displayLabel={"Suppliers"} htmlFor={"Suppliers"} />
        <MultiSelectPicker<SupplierDto>
          onChange={(items: SupplierDto[]): void => {
            setSearchFiltersState({ 
              ...searchFiltersState, 
              suppliers: items 
            });
          }}
          selectedIds={searchFiltersState.suppliers}
          itemsProps={suppliers ?? []}
          idField="id"
          labelField="supplierMnemo"
          getLabel={(item: SupplierDto) => item.supplierMnemo}
        />
      </div>
      <div className="col-md-3 form-group">
        <Label displayLabel={"Status"} htmlFor={"Status"} />
        <MultiSelectPicker<UploadStatusDto>
          onChange={(items: UploadStatusDto[]): void => {
            setSearchFiltersState({ 
              ...searchFiltersState, 
              statuses: items
            });
          }}
          selectedIds={searchFiltersState.statuses}
          itemsProps={uploadStatusesResponse?.values ?? []}
          idField="id"
          labelField="label"
          getLabel={(item: UploadStatusDto) => item.label}
        />
      </div>
      <div className="col-md-2 mt-4">
        <Button text="Reset" className="btn-lg btn-outline-secondary mb-2 mr-0" onClick={resetForm} />
        <Button
          className="btn-lg btn-primary ml-2 mb-2 mr-0"
          text="Search"
          disabled={!isSearchFilterValid()}
          onClick={handleSearchFilterClick}
        />
      </div>
    </div>
  );
}