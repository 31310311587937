import * as React from "react";

export interface InvalidFeedbackProps {
  errorMessage?: string;
  errorType?: "danger" | "warning";
}

export const InvalidFeedback: React.FC<InvalidFeedbackProps> = ({
  errorMessage,
  errorType = "danger",
}: InvalidFeedbackProps) => (
  <div className={errorType === "danger" ? "invalid-feedback" : "warning-feedback"} style={{ display: "block" }}>
    {errorMessage}
  </div>
);
