import { SupplierFrequency } from "../common/suppliers.typings";

export const displayUploadFrequency = (uploadFrequency: string): string => {
  switch(uploadFrequency) {
    case SupplierFrequency.Monthly:
      return "Monthly";
    case SupplierFrequency.HalfYearly:
      return "Half Yearly";
    case SupplierFrequency.Quarterly:
      return "Quarterly";
    case SupplierFrequency.Yearly:
      return "Yearly";
    default:
      return "";
  }
}