import React from "react";
import { useGetSuppliers } from "../../../../queries/suppliers";
import { SupplierDto, SuppliersStatus } from "../suppliers.typings";

export interface Props {
  show: boolean;
  selectedItem: SupplierDto;
  onChange: (newValue: SupplierDto) => void;
}

export const useGetAuthorizedSuppliers = () => {
  const [suppliers, setSuppliers] = React.useState<SupplierDto[] | null>(null);
  const { data: suppliersResponse } = useGetSuppliers(SuppliersStatus.Active, suppliers === null);
  
  React.useEffect(() => {
    if (suppliersResponse) {
      setSuppliers(suppliersResponse);
    }
  }, [suppliersResponse]);

  return [ suppliers ] as const;
}