export enum SupplierFrequency {
  Monthly = "monthly",
  Quarterly = "quarterly",
  HalfYearly = "halfYearly",
  Yearly = "yearly"
}

export interface SupplierDto {
  id: number;
  supplierMnemo: string;
  supplierLabel: string;
  uploadFrequency: string;
}

export interface SupplierDtos {
  suppliers: SupplierDto[];
}

export enum SuppliersStatus {
  All = "",
  Active = "active",
  Inactive = "inactive"
}