import { useMutation, UseMutationResult, useQuery } from "react-query";
import { UploadApi } from "../api/upload.api";
import { buildAccountingPeriod } from "../components/DataCollect/common/common.helpers";
import { CheckLastSuccessUploadDto, FileUploadDto, FileUploadHistoryDto, FileUploadResponseDto, UploadStatusesDto } from "../components/DataCollect/common/upload.typings";
import { toastifyError, toastifySuccess } from "../components/utils/toastify/toasterNotifier";

export const useUploadFile = (): UseMutationResult<
  FileUploadResponseDto,
  unknown,
  FileUploadDto
> => {
  return useMutation((fileUploadDto) => UploadApi.postUpload(fileUploadDto).then((res) => 
  { 
    var isSuccess = res.fileComments.findIndex(c => c.rejectFile) === -1;
    if(isSuccess)
      toastifySuccess("File upload is successful");
    else
      toastifyError("File upload is rejected")
    return res; 
  }));
};

export const useGetUploadStatuses = (enabled: boolean) =>
  useQuery<UploadStatusesDto>(["statuses"], () => UploadApi.getUploadStatuses().then((res) => res), {
    enabled: enabled,
    refetchOnWindowFocus: false,
  });

export const useCheckLastSuccessUpload = (fileUploadDto: FileUploadDto, enabled: boolean) =>
  useQuery<CheckLastSuccessUploadDto>(["lastsuccessupload"], () => UploadApi.CheckLastSuccessUpload(fileUploadDto.supplier.supplierMnemo, buildAccountingPeriod(fileUploadDto.accountingPeriodYear, fileUploadDto.accountingPeriodMonth))
  .then((res) => res),
  {
    enabled: enabled,
  });

const downloadFile = async (response: Response): Promise<void> => {
  const content = response.headers.get("Content-Disposition");
  const formatedFileName = content!.split(";")[1]
    .trim()
    .split("=")[1]
    .replace(/"/g, "");
  
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = formatedFileName;
  a.click();
  URL.revokeObjectURL(downloadUrl);
}

export const downloadUploadFile = async (uploadId: number): Promise<void> => {
  const response = await UploadApi.DownloadUploadFile(uploadId);
  downloadFile(response);
}

export const downloadUnknownClientsFile = async (uploadId: number): Promise<void> => {
  const response = await UploadApi.downloadUnknownClientsFile(uploadId);
  downloadFile(response);
}

export const useGetUploads = (accountingPeriodFrom: string, accountingPeriodTo: string, supplierIds: number[], statuses: string[], enabled: boolean) =>
  useQuery<FileUploadHistoryDto[]>(["getuploads"], () => UploadApi.getUploads(accountingPeriodFrom, accountingPeriodTo, supplierIds, statuses)
  .then((res) => res.uploads),
  {
    enabled: enabled,
  });
