import { SupplierDtos, SuppliersStatus } from "../components/DataCollect/common/suppliers.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { ISuppliersApi } from "./suppliers.api.typing";

export const SuppliersApi: ISuppliersApi = {
  getSuppliers: (status?: SuppliersStatus): Promise<SupplierDtos> =>
    createRepository().get<SupplierDtos>(
      `${process.env.REACT_APP_DATACOLLECT_API}/api/v1/suppliers?status=${status ?? ""}`,
      getAuthenticationHeader()
  ),
};