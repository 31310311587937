import { ChildrenRenderProps, Select, SGBSSize } from "@sgbs-ui/core";
import { isEmpty } from "lodash";
import * as React from "react";
import { InvalidFeedback } from "../ValidationMessage/InvalidFeedback";

export interface Props<T> {
  placeholder?: string;
  disabled?: boolean;
  selectedIds: T[];
  withFunds?: boolean;
  size?: SGBSSize;
  outline?: boolean;
  onChange: (status: T[]) => void;
  itemsProps: T[];
  inError?: boolean;
  errorMessage?: string;
  idField: string;
  labelField: string;
  getLabel: (item: T) => string;
}

const MultiSelectPicker: <T>(props: Props<T>) => React.ReactElement = <T extends any>({
  errorMessage,
  inError,
  selectedIds,
  placeholder,
  onChange,
  disabled,
  size,
  outline,
  itemsProps,
  idField,
  labelField,
  getLabel
}: Props<T>) => {
  const [filteredItems, setFilteredItems] = React.useState<T[]>([]);

  React.useEffect(() => {
    setFilteredItems(itemsProps);
  }, [itemsProps, selectedIds]);

  const handleOnSelect = (selected: T[]): void => {
    if (selected) {
      onChange(selected);
    }
  };

  const onTermsChange = (terms: string): void => {
    if (!isEmpty(terms)) {
      const items = itemsProps?.filter((value) => getLabel(value).toLocaleLowerCase().includes(terms.toLocaleLowerCase()));
      setFilteredItems(items);
    } else if (isEmpty(terms)) {
      setFilteredItems(itemsProps);
    }
  };

  const renderItems = (value: T, { withHighlight }: ChildrenRenderProps): React.ReactElement => {
    return <div>{withHighlight(getLabel(value) ?? "")}</div>;
  };

  const error = errorMessage ?? "";

  return (
    <>
      <Select.AsyncSelect<T>
        items={filteredItems}
        selectedItems={selectedIds}
        isLoading={false}
        idField={idField}
        disabled={disabled}
        labelField={labelField}
        onTermChange={onTermsChange}
        onChange={handleOnSelect}
        size={size}
        iconName="search"
        placeholder={placeholder}
        keepOrder={false}
        isOutline={outline}
        inError={inError}
        errorMessage={error}
        noResultMessage={"No results found."}
      >
        {renderItems}
      </Select.AsyncSelect>
      {error && <InvalidFeedback errorMessage={error} />}
    </>
  );
};

export default MultiSelectPicker;
