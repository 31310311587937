import { get } from "lodash";
import { stringifyParams } from "../components/utils/strings/stringUtils";
import { toastifyError, toastifySuccess } from "../components/utils/toastify/toasterNotifier";
import { getAuthorizationHeader, getUserEmail } from "../helpers";
import { HttpRequestHeader, ToastMessage } from "./api.typings";

type Request =
  | {
      method: "GET";
      headers: HttpRequestHeader;
      url: string;
      params: object | undefined;
    }
  | {
      method: "POST" | "PUT" | "PATCH";
      headers: HttpRequestHeader;
      url: string;
      body: BodyInit;
    };

function createRequest(request: Request, includeGetDefaultHeader = true) {
  if (request.method === "GET") {
    const { url, params, method, headers } = request;
    const requestUrl = `${url}${stringifyParams(params)}`;
    return fetch(requestUrl, { method, headers: { ...headers } });
  } else {
    const { url, body, method, headers } = request;
    const requestUrl = `${url}`;
    return fetch(requestUrl, { method, headers: { ...headers }, body });
  }
}

async function tryCatch(
  f: () => Promise<Response>,
  toastMessage?: ToastMessage,
  needToastifySuccess = false,
  returnAsJson = true
): Promise<any> {
  try {
    const result = await f();
    if (!result.ok) {
      switch(result.status) {
        case 400:
          const jsonResult = await result.json();
          const badRequestErrorMessage =  jsonResult.detail 
          ?? Object.keys(jsonResult).map(key => {
            const message = jsonResult[key].message;
            const lines = jsonResult[key].lines as number[];
            switch(lines.length){
              case 0:
                return message;
              case 1:
                return `${message} at line: ${lines[0]}`
              case 2:
              case 3:
              case 4:
              case 5:
                return `${message} at lines: ${lines.join(", ")}`
              default:
                return `${message} at lines: ${lines.slice(0,5).join(", ")}, ...`
            }
          }).join("\n");
          toastifyError(get(toastMessage, "badRequestErrorMessage"), badRequestErrorMessage);
          break;
        case 401:
          toastifyError(get(toastMessage, "notAuthorizedErrorMessage"), toastMessage?.notAuthorizedErrorMessage)
          break;
        case 403:
          toastifyError(get(toastMessage, "forbiddenErrorMessage"), toastMessage?.forbiddenErrorMessage)
          break;
        case 404:
          toastifyError(get(toastMessage, "notFoundErrorMessage"), toastMessage?.notFoundErrorMessage)
          break;
        default:
          toastifyError(get(toastMessage, "friendlyErrorMessage"), toastMessage?.friendlyErrorMessage)
          break;  
      }
      return Promise.reject(result);
    }
    if (needToastifySuccess) {
      toastifySuccess(toastMessage ? toastMessage.successMessage : "");
    }
    if (returnAsJson) {
      return await result.json();
    }
    return result;
  } catch (error) {
    toastifyError(get(toastMessage, "friendlyErrorMessage") || error);
    return Promise.reject(error);
  }
}

export const createRepository = () => ({
  get: <T>(url: string, headers: HttpRequestHeader, params?: object, toastMessage?: ToastMessage): Promise<T> => {
    const getFetch = () => createRequest({ method: "GET", headers, url, params }, false);
    return tryCatch(getFetch, toastMessage);
  },
  post: <B extends BodyInit, R>(
    url: string,
    headers: HttpRequestHeader,
    body: B,
    toastMessage?: ToastMessage
  ): Promise<R> => {
    const postFetch = () => createRequest({ method: "POST", headers, url, body });
    return tryCatch(postFetch, toastMessage);
  },
  getStream: <Response>(
    url: string, 
    headers: HttpRequestHeader, 
    params?: object, 
    toastMessage?: ToastMessage
  ): Promise<Response> => {
    const getFetch = () => createRequest({ method: "GET", headers, url, params }, false);
    return tryCatch(getFetch, toastMessage, false, false);
  }
});

export const getAuthenticationHeader = (): HttpRequestHeader => ({
  authorization: getAuthorizationHeader(),
  userEmail: getUserEmail(),
});
