import { CerberusResponse } from "../common/common.typings";
import { createRepository, getAuthenticationHeader } from "./api";
import { IUserApi } from "./users.api.typings";

export const UserApi: IUserApi = {
  getUser: (email: string): Promise<CerberusResponse> => createRepository().get<CerberusResponse>(
    `${process.env.REACT_APP_CERBERUS_API}/api/v1/users/${email}`,
    getAuthenticationHeader()
  ),
};
